//== DEFAULTS RAILS
  import Rails from "@rails/ujs"
  Rails.start()


//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

//
// TYPEAHEAD
  import { typeahead } from "jquery-typeahead/dist/jquery.typeahead.min";

  $('.fulltext-search').typeahead({
    minLength: 3,
    highlight: true,
    source: {
      ajax: {
        type: "GET",
        url: '/get_search_data',
        data: {
          q: '{{query}}'
        }
      }
    },
    display: 'display_value',
    limit: 50,
    templates: {
      suggestion: function(data) {
        return '<p><strong>' + data.display_value + '</strong></p>';
      }
    },
    emptyTemplate: function (query) {
      if (query.length > 0) {
        return 'Keine Ergebnisse gefunden für "' + query + '"';
      }
    }
  });

//
//
// FANCYBOX
  import "components/fancybox";


//== BOOTSTRAP
  require("bootstrap")


//
//
// MOBILE MENUE
$('#navbar_main ul li.dropdown > a').click(function(e) {
  if ($(window).width() <= 991.98){
    if ($(this).next('ul').is(':hidden') ) {
      $('#navbar_main ul li.dropdown ul').hide();
      $(this).next('ul').show();
    } else {
      $(this).next('ul').hide();
    }

    e.preventDefault();
  }
});


//== SCROLL TOP
  window.addEventListener("scroll", function(){
    let scroll = document.querySelector('.scroll-to-top');
    scroll.classList.toggle("active", window.scrollY > 500)
  })
